@font-face {
    font-family: 'Mont Thin';
    src: local('Mont Thin'), url('../../public/fonts/Mont-Thin.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Mont Hairline';
    src: local('Mont Hairline'), url('../../public/fonts/Mont-Hairline.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Mont Light';
    src: local('Mont Light'), url('../../public/fonts/Mont-Light.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Mont ExtraLight';
    src: local('Mont ExtraLight'), url('../../public/fonts/Mont-ExtraLight.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Mont Book';
    src: local('Mont Book'), url('../../public/fonts/Mont-Book.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Mont Regular';
    src: local('Mont Regular'), url('../../public/fonts/Mont-Regular.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Mont SemiBold';
    src: local('Mont SemiBold'), url('../../public/fonts/Mont-SemiBold.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Mont Bold';
    src: local('Mont Bold'), url('../../public/fonts/Mont-Bold.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Mont Black';
    src: local('Mont Black'), url('../../public/fonts/Mont-Black.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Mont Heavy';
    src: local('Mont Heavy'), url('../../public/fonts/Mont-Heavy.ttf') format('truetype');
    font-display: swap;
}