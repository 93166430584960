// colors
$blue-primary: #16121c;
$blue-dark: #110e17;
$blue-darker: #0e0b12;
$yellow-primary: #dcc81b;
$yellow-dark: #d4c119;
$yellow-darker: #cebb16;
$white: #fff;
$grey: #dedede;
$dark-grey: #515151;

// fonts
$font: (
    "thin": "Mont Thin",
    "hairline": "Mont Hairline",
    "light": "Mont Light",
    "extralight": "Mont ExtraLight",
    "book": "Mont Book",
    "regular": "Mont Regular",
    "semibold": "Mont SemiBold",
    "bold": "Mont Bold",
    "black": "Mont Black",
    "heavy": "Mont Heavy",
);

// widths
$mobile: 650px;
$tab: 1200px;
$small: 1400px;
$medium: 1750px;
$large: 2000px;

$fixed-width: 1100px;
$full-width: 100%;