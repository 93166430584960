
  .form-control {
    font-family: "Mont Regular";
    font-size: 1em;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    float: left;
    color: #fff;
}
  
.form-control + .form-control {
    margin-left: 20px;
}
  
.form-control:focus-within {
    color: #fff;
}
  
input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
  
    font: inherit;
    color: #fff;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);
  
    display: grid;
    place-content: center;
}
  
input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: #dcc81b;
}
  
input[type="radio"]:checked::before {
    transform: scale(1);
}
  